import React, { useEffect, useState } from 'react';
import './index.css';
import AgeDetectionForm from './components/AgeDetectionForm';
import { useTranslation } from 'react-i18next';

function App() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        document.title = t('title');
    }, [language,t]);

    const handleLanguageChange = () => {
        const newLanguage = language === 'en' ? 'pl' : 'en';
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-blue-500 py-8 text-white text-3xl font-bold text-center">
                {t('header')}
            </header>
            <div className="flex justify-end ">
                <button
                    onClick={() => handleLanguageChange('pl')}
                    className={`border border-blue-500 rounded-l px-2 py-1 ${language === 'pl' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
                >
                    PL
                </button>
                <button
                    onClick={() => handleLanguageChange('en')}
                    className={`border border-blue-500 rounded-r px-2 py-1 ${language === 'en' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
                >
                    EN
                </button>
            </div>

            <main className="py-8">
                <section className="mx-auto max-w-4xl mb-12 px-4 bg-white rounded-lg shadow-lg p-8 text-justify">
                    <h2 className="text-2xl font-bold mb-4 text-blue-800 text-center">
                        {t('introTitle')}
                    </h2>
                    <p className="mb-4 text-gray-700">
                        {t('paragraph1')}
                    </p>
                    <p className="mb-4 text-gray-700">
                        {t('paragraph2')}
                    </p>
                    <p className="text-gray-700">
                        {t('paragraph3')}
                    </p>
                </section>
                <div className="bg-white shadow-xl rounded-lg mx-auto max-w-4xl p-8">
                    <AgeDetectionForm />
                </div>
            </main>
        </div>
    );
}

export default App;
