import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            title: "iNova Age Detection",
            header: "iNova Age Detection Demo",
            introTitle: "Introducing iNova's Age Detection API",
            paragraph1: "iNova's Age Detection API utilizes cutting-edge deep learning technology to estimate the age range of individuals in images with remarkable accuracy. Our highly trained models can analyze and recognize facial features to provide a reliable estimation of a person's age.",
            paragraph2: "The age detection API can be easily integrated into a wide range of applications, including identity verification, age-restricted content management, targeted advertising, and more. Our technology is designed to be user-friendly, scalable, and compatible with various platforms, making it the perfect solution for your age detection needs.",
            paragraph3: "Experience the power of iNova's Age Detection API with this demo. Upload an image with a single face, and our API will provide an estimated age range in just a few seconds. Join us in embracing the future of age detection technology with iNova.",
            apiKey: 'API Key',
            uploadImage: 'Upload Image',
            detectAge: 'Detect Age',
            estimatedAgeRange: 'Estimated age range:',
            years: 'years',
            noFaceOrMultipleFaces: 'No face or multiple faces detected in the image. Please upload an image with a single face.',
            quotaReached: 'Quota for the day has been reached. Please try again later.',
            errorOccurred: 'An error occurred. Please try again.',
            httpError: 'An HTTP error occurred: status',
        },
    },
    pl: {
        translation: {
            title: "iNova Wykrywanie wieku",
            header: "Demo iNova Wykrywanie wieku",
            introTitle: "Prezentujemy API iNova do wykrywania wieku",
            paragraph1: "API iNova do wykrywania wieku wykorzystuje zaawansowaną technologię uczenia głębokiego w celu oszacowania przedziału wiekowego osób na zdjęciach z niezwykłą precyzją. Nasze wysoko wyszkolone modele potrafią analizować i rozpoznawać cechy twarzy, dostarczając wiarygodnego oszacowania wieku osoby.",
            paragraph2: "API do wykrywania wieku można łatwo zintegrować z szeroką gamą aplikacji, takich jak weryfikacja tożsamości, zarządzanie treściami z ograniczeniami wiekowymi, reklama ukierunkowana i wiele innych. Nasza technologia została zaprojektowana tak, aby była przyjazna dla użytkownika, skalowalna i kompatybilna z różnymi platformami, co czyni ją idealnym rozwiązaniem dla Twoich potrzeb związanych z wykrywaniem wieku.",
            paragraph3: "Doświadcz mocy API iNova do wykrywania wieku dzięki temu demo. Prześlij zdjęcie z jedną twarzą, a nasze API poda szacunkowy przedział wiekowy w zaledwie kilka sekund. Przyłącz się do nas w objęciu przyszłości technologii wykrywania wieku z iNova.",
            apiKey: 'Klucz API',
            uploadImage: 'Prześlij zdjęcie',
            detectAge: 'Wykryj wiek',
            estimatedAgeRange: 'Szacowany przedział wiekowy:',
            years: 'lat',
            noFaceOrMultipleFaces: 'Brak twarzy lub wykryto wiele twarzy na zdjęciu. Proszę przesłać zdjęcie z pojedynczą twarzą.',
            quotaReached: 'Osiągnięto limit na dzisiaj. Proszę spróbować później.',
            errorOccurred: 'Wystąpił błąd. Proszę spróbować ponownie.',
            httpError: 'Wystąpił błąd HTTP: status',
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
