import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AgeDetectionForm = () => {
    const { t } = useTranslation();
    const [image, setImage] = useState(null);
    const [minAge, setMinAge] = useState(null);
    const [maxAge, setMaxAge] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleApiKeyChange = (e) => {
        setApiKey(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const response = await fetch('https://api.inova-it.pl/analyze', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({b64_image: image.split(',')[1]}),
            });
            if (response.status === 400) {
                setErrorMessage(t('noFaceOrMultipleFaces'));
            } else if (response.status === 429) {
                setErrorMessage(t('quotaReached'));
            } else if (!response.ok) {
                setErrorMessage(t('errorOccurred'));
            } else {
                const result = await response.json();
                setMinAge(result.min_age);
                setMaxAge(result.max_age);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(`${t('httpError')} ${error.response.status}`);
            } else {
                setErrorMessage(`${t('errorOccurred')}: ${error.message}`);
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center">
            <form onSubmit={handleSubmit} className="w-full max-w-md">
                {/* Input field for API Key */}
                <div className="mt-4">
                    <label htmlFor="apiKey" className="block text-gray-700 text-sm font-bold mb-2">
                        {t('apiKey')}
                    </label>
                    <input
                        type="text"
                        id="apiKey"
                        name="apiKey"
                        value={apiKey}
                        onChange={handleApiKeyChange}
                        className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

                {/* Input field for Image */}
                <div className="mt-4">
                    <label htmlFor="image" className="block text-gray-700 text-sm font-bold mb-2">
                        {t('uploadImage')}
                    </label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/png, image/jpeg"
                        onChange={handleImageChange}
                        className="appearance-none border-2 border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                    {isLoading ? (
                        <span className="animate-spin inline-block h-5 w-5 border-t-2 border-white rounded-full"></span>
                    ) : (
                        t('detectAge')
                    )}
                </button>
            </form>

            {errorMessage && (
                <div className="mt-4 text-red-600 font-bold border-2 border-red-600 rounded p-2 w-full max-w-md">
                    {t(errorMessage)}
                </div>
            )}

            {minAge !== null && maxAge !== null && (
                <div className="mt-4 text-lg font-bold bg-green-100 border-2 border-green-500 rounded p-2 w-full max-w-md">
                    {t('estimatedAgeRange')} {minAge} - {maxAge} {t('years')}
                </div>
            )}
        </div>
    );
};

export default AgeDetectionForm;
